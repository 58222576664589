<template>
  <b-container fluid>
    <b-row class="my-4">
      <b-col>
        <span v-if="permissions.addArticle"><b-button class="mr-2" variant="outline-primary" v-on:click="addArticle">new article</b-button></span>
        <b-form-group label-for="search" label="search" label-cols-lg="2" content-cols-lg="8" label-align-lg="right">
          <b-form-input v-model="filter" id="search" placeholder="type to search" />
        </b-form-group>
        <b-table style="font-size: 12px" striped hover :items="articles" :fields="fields" :filter="filter">
          <template #cell(title)="data">
            <b-link :to="{ name: 'ArticleEditor', params: { id: data.item.id }}">{{ data.item.title }}</b-link>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '../libs/accesscontrol'
import moment from 'moment'

export default {
  components: {},
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.$stat.log({ page: 'articles', action: 'open articles' })
    if (!ac.can(this.user.acgroups).readAny('article').granted) {
      this.$router.push({ name: 'Home' })
    }
    this.permissions.addArticle = ac.can(this.user.acgroups).createAny('article').granted
    try {
      this.articles = await this.$Amplify.API.get('cosmos', '/articles/all/flat')
    } catch (e) {
      this.$logger.warn('fetching articles error: ' + e)
    }
  },
  data () {
    return {
      articles: [],
      fields: [
        { key: 'id', sortable: true },
        { key: 'title', sortable: true },
        { key: 'del', sortable: true },
        { key: 'created_by', sortable: true },
        { key: 'created_at', sortable: true, formatter: value => moment(value).format('YYYY-MM-DD HH:mm') },
        { key: 'approved_by', sortable: true },
        { key: 'approved_at', sortable: true, formatter: value => value === null ? 'not approved' : moment(value).format('YYYY-MM-DD HH:mm') }
      ],
      filter: '',
      permissions: {}
    }
  },
  methods: {
    addArticle: async function () {
      try {
        const config = {
          article: {
            title: 'New article',
            body: '<p>work in progress...</p>',
            created_by: this.user.username
          }
        }
        let params = {
          body: config
        }
        let response = await this.$Amplify.API.put('cosmos', '/article', params)
        this.$stat.log({ page: 'articles', action: 'created article', model: 'article', model_id: response.id, payload: response })
        this.$logger.debug('saved OK:', response)
        this.$router.push({ name: 'ArticleEditor', params: { id: response.id } })
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
    }
  }
}
</script>

<style lang="scss">
</style>
